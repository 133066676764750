import React, { useEffect, useState } from "react";
import useFetch from "../api/useFetch";
import Table from "./Table";
import Loading from "./Loading";
import usePost from "../api/usePost";
import funcs from '../utils/utils';


const Payrolls = ({ notify }) => {
  const tableHeader = [
    { content: "Technician", size: "lg", isLink: false },
    { content: "Week", size: "sm", isLink: false },
    { content: "Year", size: "sm", isLink: false },
    { content: "Amount", size: "sm", isLink: false },
    { content: "Details", size: "sm", isLink: true, link: "/payroll" },
  ];

  const [tableContent, setTableContent] = useState(null);
  const [week, setWeek] = useState(funcs.getWeekNumber());
  const [year, setYear] = useState(funcs.getYear());
  const [technician, setTechnician] = useState(null);
  const { postData } = usePost();
  const [refresh, setRefresh] = useState(false);
  const { result: payrolls, isLoading: payrollsLoading, error: payrollsError } =
    useFetch("/payrolls", [refresh]);

  useEffect(() => {
    if (payrolls?.items) {
      setTechnician(payrolls.technicians[0].id);
      setTableContent(
        payrolls.items.sort((a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year;
          }
          return a.week - b.week;
        }).map((item) => [
          item.contractor.person.first_name,
          item.week,
          item.year,
          `$${item.amount}`,
          item.id,
        ])
      );
    }
  }, [payrolls]);

  const processPayroll = async () => {
    const data = { technician_id: technician, week: week, year: year };
    console.log(data);
    const response = await postData("/process_payroll", data);
  
    if (response) {
      notify(response.message);
      setRefresh(!refresh);
    }
  };
  
  return (
    <>
      {payrollsLoading && <Loading />}
      {payrolls && (
        <>
          <div style={{ marginTop: "25px" }} className="buttons">
            <div className="button">
              <div>Create Payroll</div>
              <br />
              <br />
              <form>
                <select
                  value={technician}
                  onChange={(e) => setTechnician(e.target.value)}
                >
                  <option value="" disabled>
                    Select Technician
                  </option>
                  {payrolls.technicians?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.person.first_name} {item.person.last_name}
                    </option>
                  ))}
                </select>
                <br />
                <br />
                <select value={week} onChange={(e) => setWeek(e.target.value)}>
                  {funcs.weekSelector()}
                </select>
                <br />
                <br />
                <select value={year} onChange={(e) => setYear(e.target.value)}>
                  {funcs.yearSelector()}
                </select>
                <br />
                <br />
                <button onClick={processPayroll}>Start</button>
              </form>
            </div>
          </div>
          <div className="list">
            <div className="title">PAYROLLS</div>
            {payrolls && (
              <Table tableHeader={tableHeader} tableContent={tableContent} />
            )}
            {payrollsLoading && <Loading />}
            {payrollsError && <>{payrollsError}</>}
          </div>
        </>
      )}
    </>
  );
};

export default Payrolls;
